import Collapse from 'bootstrap/js/dist/collapse';
import Modal from 'bootstrap/js/dist/modal';
import Carousel from 'bootstrap/js/dist/carousel';
import Dropdown from 'bootstrap/js/dist/dropdown';

window.bootstrap = { Collapse: Collapse, Modal: Modal, Carousel: Carousel, Dropdown: Dropdown };

const navbarToggler = Collapse.getOrCreateInstance('#navbarNav', { toggle: false });

window.addEventListener('beforeunload', () => {
    navbarToggler.hide();
})

document.querySelectorAll('body >*:not(header)').forEach(element => {
    element.addEventListener('click', () => {
        navbarToggler.hide();
    })
})

const map = document.getElementById('map');

if (map) {
    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                mapListener();
                observer.disconnect();
            }
        })
    })
    observer.observe(map);
}


document.querySelectorAll('.slide-in').forEach(element => {
    createSlideAnimationObserver(element);
})

function createSlideAnimationObserver(element) {
    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                element.classList.add('slide-in-left');
                element.classList.remove('slide-in');
                observer.disconnect();
            }
        });
    });
    observer.observe(element);
}

(g => { var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window; b = b[c] || (b[c] = {}); var d = b.maps || (b.maps = {}), r = new Set, e = new URLSearchParams, u = () => h || (h = new Promise(async (f, n) => { await (a = m.createElement("script")); e.set("libraries", [...r] + ""); for (k in g) e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]); e.set("callback", c + ".maps." + q); a.src = `https://maps.${c}apis.com/maps/api/js?` + e; d[q] = f; a.onerror = () => h = n(Error(p + " could not load.")); a.nonce = m.querySelector("script[nonce]")?.nonce || ""; m.head.append(a) })); d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)) })({
    key: "AIzaSyB5e-iUzlDdd__9co9R92skwzm1F9SSnKg",
    v: "weekly",    
});

function mapListener() {
    let map;

    async function initMap() {
        const position = { lat: 48.7225548, lng: 21.2561387 };
        const { Map } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

        map = new Map(document.getElementById("map"), {
            zoom: 15,
            center: position,
            mapId: "4d9970338220d0b7",
            clickableIcons: false
        });

        const img = document.createElement('img');
        img.src = "img/cards/card-03-300w.webp";
        img.width = "50";
        img.height = "50";
        img.style.borderRadius = "50%";

        const marker = new AdvancedMarkerElement({
            map: map,
            position: position,
            title: "Základná umelecká škola Márie Hemerkovej",
            content: img,
        });

        const infoWindow = new google.maps.InfoWindow({
            content: `<div>
            <h2 class="h6">Základná umelecká škola Márie Hemerkovej</h2>
            <a href="https://maps.app.goo.gl/LtZ3UAa6MNUjJmnq6" referrerpolicy="no-referrer" target="_blank">Zobraziť v Google Maps</a>
            </div>`
        });

        marker.addListener('gmp-click', () => {
            infoWindow.open({
                anchor: marker,
                map
            })
        });
    }

    initMap();
}